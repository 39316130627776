<template>
  <v-container>
    <v-card>
      <v-card-text>
        <v-row justify="end">
          <v-col>
            <v-btn
                class="btn-back elevation-0"
                @click="backButton()"
            >
              <v-icon dark left> mdi-arrow-left</v-icon>
              Back
            </v-btn>
          </v-col>

          <v-col>
            <div>{{ plan.name }}</div>
          </v-col>
          <v-col class="text-end">
            <v-btn class="btn-primary mr-4 elevation-0" medium @click="AddModal()">
              <v-icon>mdi-plus</v-icon>
              ເພີ່ມລຸກຄ້າ
            </v-btn>
            <v-btn class="btn-primary elevation-0" medium dark @click="openModalEdit()">
              <v-icon>mdi-pencil</v-icon>
              ແກ້ໄຂຊື່ແຜນ
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="mb-4">
            <GmapMap
                v-if="customers"
                :center="getCenter()"
                :zoom="16"
                style="width: 100%; height: 500px"
                :disableDefaultUI="true"
            >
              <gmap-info-window
                  :options="infoOptions"
                  :position="infoPosition"
                  :opened="infoOpened"
                  :conent="infoContent"
                  @closeclick="infoOpened = false"
              >{{ infoContent }}
              </gmap-info-window>
              <GmapMarker
                  :key="index"
                  v-for="(m, index) in customers"
                  :position="getMarkers(m)"
                  @click="toggleInfo(m, index)"
                  :draggable="false"
                  :animation="2"
                  :clickable="true"
              />
            </GmapMap>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
                outlined
                dense
                clearable
                label="ຄົ້ນຫາ ລະຫັດລູກຄ້າ, ຊື່"
                type="text"
                v-model="searchOld"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <main class="page page--table">
              <v-data-table
                  :loading="TableLoading"
                  :disabled="TableLoading"
                  loading-text="Loading... Please wait"
                  :headers="headers"
                  :items="customers"
                  :disable-pagination="true"
                  hide-default-footer
                  item-key="id"
                  class="page__table elevation-0"
                  :search="searchOld"
              >
                <template v-slot:body="props">
                  <draggable :list="props.items" tag="tbody" @change="afterAdd">
                    <tr v-for="(user, index) in props.items" :key="index">
                      <td>
                        <v-icon small class="page__grab-icon">
                          mdi-arrow-all
                        </v-icon>
                      </td>
                      <td>{{ user.priority }}</td>
                      <td>{{ user.customer_code }}</td>
                      <td>{{ user.name }}</td>
                      <td>{{ user.phone }}</td>
                      <td>{{ user.villageName }}</td>
                      <td>{{ user.districtName }}</td>
                      <td>{{ user.ຈຸດສັງເກດ }}</td>
                      <th>
                        <v-chip :color="setStatus(user.status)" @click="changeStatus(user)">
                          {{ user.status }}
                        </v-chip>
                        <!--                    <v-switch-->
                        <!--                        v-model="user.active"-->
                        <!--                        inset-->
                        <!--                    :label="`Active: ${setStatus(user.status)}`"-->
                        <!--                    ></v-switch>-->
                      </th>

                      <td>
                        <!--
                          <v-icon small class="mr-2" @click="viewPage(user.id)">
                            mdi-eye
                          </v-icon>
                          -->
                        <v-icon medium @click="deleteItem(user.id)">
                          mdi-delete
                        </v-icon>
                      </td>
                    </tr>
                  </draggable>
                </template>
              </v-data-table>
            </main>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h3>ລູກຄ້າເພີ່ມເຂົ້າມາໃໝ່</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-col>
              <main class="page page--table">
                <v-data-table
                    :loading="TableLoading"
                    :disabled="TableLoading"
                    loading-text="Loading... Please wait"
                    :headers="newCustomerHeaders"
                    :items="listNewCustomers"
                    :disable-pagination="true"
                    hide-default-footFer
                    item-key="id"
                    class="page__table elevation-0"
                >
                  <template v-slot:body="props">
                    <draggable :list="props.items" tag="tbody" @change="orderNewCustomer">
                      <tr v-for="(user, index) in props.items" :key="index">
                        <td>
                          <v-icon small class="page__grab-icon">
                            mdi-arrow-all
                          </v-icon>
                        </td>
                        <td>{{ index + 1 }}</td>
                        <td>{{ user.customer_code }}</td>
                        <td>{{ user.name }}</td>
                        <td>{{ user.phone }}</td>
                        <td>{{ user.village }}</td>
                        <td>{{ user.district }}</td>
                        <td>{{ user.address }}</td>
                        <td>
                          <v-icon small @click="deleteNewCustomer(index)">
                            mdi-delete
                          </v-icon>
                        </td>
                      </tr>
                    </draggable>
                  </template>
                </v-data-table>
              </main>
            </v-col>
          </v-col>
        </v-row>

        <!--Delete Modal-->
        <ModalAdd>
          <template @close="close">
            <v-card>
              <v-card-text>
                <v-card-title>
                  <span>ເພີ່ມລູກຄ້າເຂົ້າແຜນ</span>

                  <v-spacer></v-spacer>
                  <v-btn class="btn elevation-0 error" @click="CloseModalAdd()">
                    Close
                  </v-btn>

                </v-card-title>
                <v-container>
                  <v-row>
                    <v-col cols>
                      <v-select
                          dense
                          outlined
                          required
                          v-model="selectedDistrict"
                          :items="districts"
                          item-text="name"
                          item-value="id"
                          label="ເລືອກເມືອງ"
                          multiple
                      >
                      </v-select>
                    </v-col>
                    <v-col cols>
                      <v-checkbox v-model="has_plan"
                                  label="ມີແຜນເສັ້ນທາງແລ້ວ"
                                  row>
                      </v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-autocomplete
                          dense
                          outlined
                          required
                          v-model="selectedVillages"
                          :items="listVillages"
                          item-text="name"
                          item-value="id"
                          label="ບ້ານ"
                          multiple
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col>
                      <v-text-field
                          outlined
                          dense
                          clearable
                          label="ຄົ້ນຫາ ລະຫັດລູກຄ້າ, ຊື່"
                          type="text"
                          v-model="search"
                          @keyup.enter="Search()"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-data-table
                          :headers="newCustomerHeaders"
                          :items="newCustomers"
                          class="elevation-1"
                          :loading="TableLoading"
                          :disabled="TableLoading"
                          :disable-pagination="true"
                          :search="search"
                          hide-default-footer
                          loading-text="Loading... Please wait"
                      >
                        <!--Action -->
                        <template v-slot:item.actions="{ item }">
                          <v-btn :loading="addLoading" :disabled="addLoading" class="btn-primary elevation-0" @click="AddNewCustomerToList(newCustomers,item)">
                            <v-icon small class="mr-2">
                              mdi-plus
                            </v-icon>
                          </v-btn>

                        </template>
                      </v-data-table>
                      <br/>
                      <template>
                        <Pagination
                            v-if="pagination.total_pages > 1"
                            :pagination="pagination"
                            :offset="offset"
                            @paginate="fetchData()"
                        ></Pagination>
                      </template>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </template>
        </ModalAdd>

        <ModalEdit>
          <template @close="close">
            <v-card>
              <v-card-title>
                <span>ແກ້ໄຂແຜນເສັ້ນທາງ</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-form ref="form" lazy-validation>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                            label="ຊື່ແຜນ*"
                            required
                            v-model="plan.name"
                        ></v-text-field>
                        <p class="errors">
                          {{ server_errors.name }}
                        </p>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                            label="Color"
                            required
                            v-model="plan.color_code"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                            label="Description"
                            required
                            v-model="plan.description"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <div class="field">
                          <div class="file is-large is-boxed">
                            <label class="file-label">
                              <input
                                  @change="onFileChange"
                                  class="file-input input-file-image"
                                  type="file"
                                  name="image"
                                  accept="image/*"
                                  ref="image"
                                  hidden
                              />
                              <span class="file-cta">
                                                  <span class="file-icon">
                                                    <v-icon
                                                        style="
                                                        font-size: 60px !important;
                                                        color: #719aff;
                                                        cursor: pointer;
                                                      "
                                                        class="fas fa-cloud-upload"
                                                    >mdi-cloud-upload</v-icon
                                                    >
                                                  </span>
                                                  <span
                                                      class="file-label"
                                                      style="
                                                      margin-top: 10px;
                                                      text-transform: uppercase;
                                                      padding-top: 20px;
                                                    "
                                                  >
                                                    Map Icon
                                                  </span>
                                                </span>
                            </label>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <p class="errors">
                          {{ server_errors.image }}
                        </p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="mt-5" v-if="image">
                        <v-avatar class="avatar rounded" size="94px">
                          <img :src="imageUrl" alt=""/>
                        </v-avatar>
                      </v-col>
                      <v-col class="mt-5" v-else>
                        <v-avatar
                            class="avatar rounded mr-6"
                            size="94px"
                        >
                          <img v-if="plan.icon" :src="plan.icon"/>
                        </v-avatar>
                      </v-col>

                    </v-row>

                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="CloseModalEdit()">
                  Close
                </v-btn>
                <v-btn
                    color="blue darken-1"
                    text
                    :loading="loading"
                    :disabled="loading"
                    @click="CloseModalEdit()"
                >
                  ແກ້ໄຂ
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </ModalEdit>

        <!--Delete Modal-->
        <ModalDelete>
          <template>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete()"
              >Cancel
              </v-btn
              >
              <v-btn
                  color="blue darken-1"
                  text
                  :loading="loading"
                  :disabled="loading"
                  @click="deleteRoutePlanDetail()"
              >OK
              </v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </template>
        </ModalDelete>

        <v-row>
          <v-col class="text-end">
            <v-btn @click="UpdateRoutePlan" class="btn btn-primary elevation-0" :loading="loading" :disabled="loading">
              ແກ້ໄຂ
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
// import manage from "@/mixins/routeplan/create_plan";
import draggable from "vuedraggable";
import {GetOldValueOnInput} from "../../../Helpers/GetValue";

export default {
  components: {
    draggable,
  },
  // mixins: [manage],
  data() {
    return {
      TableLoading: false,
      loading: false,
      addLoading:false,
      routePlanDetailId: '',
      is_active: null,
      //Pagination
      offset: 15,
      pagination: {},
      per_page: 500,
      customers: [],
      newCustomers: [],
      listNewCustomers: [],
      plan: {},
      selectedRow: [],
      has_plan: 0,
      selectedVillages: [],
      listVillages: [],
      districts: [],
      selectedDistrict: [],
      server_errors: {},
      search: "",
      searchOld:"",
      headers: [
        {
          text: "",
          align: "start",
          sortable: false,
        },
        {
          text: "ລຳດັບ",
          align: "center",
          sortable: false,
          value: "priority",
        },
        {
          text: "ລະຫັດ",
          align: "start",
          sortable: false,
          value: "customer_code",
          width: "120px"
        }, {
          text: "ຊື່ລູກຄ້າ",
          align: "start",
          sortable: false,
          value: "name",
          width: "140px"
        },
        {text: "ເບົໂທ", value: "phone"},
        {text: "ບ້ານ", value: "villageName"},
        {text: "ເມືອງ", value: "districtName"},
        {text: "ຈຸດສັງເກດ", value: "address", width: "140px"},
        {text: "Inactive/Active", value: "status", width: "100px;", align: "center",},
        {text: "", value: "actions", width: "80px;"},
      ],
      newCustomerHeaders: [
        {
          text: "ລະຫັດ",
          align: "start",
          sortable: false,
          value: "customer_code",
          width: "150px",
        }, {
          text: "ຊື່ລູກຄ້າ",
          align: "start",
          sortable: false,
          value: "name",
        },
        {text: "ເບົໂທ", value: "phone"},
        {text: "ບ້ານ", value: "village"},
        {text: "ເມືອງ", value: "district"},
        {text: "", value: "actions"},
      ],

      //Map
      latlng: {
        lat: 18.1189434,
        lng: 102.290218,
      },
      markers: [],
      places: [],
      currentPlace: null,
      infoPosition: null,
      infoContent: null,
      infoOpened: false,
      infoCurrentKey: null,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      toast: {
        value: true,
        color: 'success',
        msg: 'Success'
      },
      toast_error: {
        value: true,
        color: 'error',
        msg: 'Something when wrong!'
      },
      image: "",
      imageUrl: "",
    }
  },
  metaInfo() {
    return {
      title: `ແກ້ໄຂແຜນຈັດສົ່ງ`,
    }
  },
  methods: {

    onFileChange(e) {
      let input = e.target;
      let file = e.target.files[0];
      this.image = input.files[0];
      this.imageUrl = URL.createObjectURL(file);
      this.server_errors.imageFile = '';
      let formData = new FormData();
      formData.append("imageFile", this.image);
      this.$axios.post('/upload-image', formData)
          .then(res => {
            if (res.status == 200) {
              this.image = res.data.fileName;
            }
          }).catch(error => {
        this.image = '';
        if (error.response.status == 422) {
          const obj = error.response.data.errors;
          for (let [key, user] of Object.entries(obj)) {
            this.server_errors[key] = user[0];
          }
        }
      });
    },

    backButton() {
      this.$router.go(-1);
    },
    getSiteIcon() {
      const pin1 = {
        // url: require("@/assets/vari_pin.png"),
        url: null,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
        size: {
          width: 35,
          height: 55,
          f: "px",
          b: "px",
        },
        scaledSize: {
          width: 35,
          height: 55,
          f: "px",
          b: "px",
        },
      };
      // const pin2 = {
      //   url: require("@coms/../../src/assets/vari_pin.png"),
      //   zoomControl: true,
      //   mapTypeControl: false,
      //   scaleControl: false,
      //   streetViewControl: false,
      //   rotateControl: false,
      //   fullscreenControl: false,
      //   disableDefaultUi: false,
      //   size: {
      //     width: 35,
      //     height: 55,
      //     f: "px",
      //     b: "px",
      //   },
      //   color: "#000",
      //   scaledSize: {
      //     width: 35,
      //     height: 55,
      //     f: "px",
      //     b: "px",
      //   },
      // };

      // try {
      //   switch () {
      //     case 0:
      //       return pin1;
      //     case 1:
      //       return pin2;
      //   }
      // } catch (e) {
      //   return pin1;
      // }
      return pin1;
    },
    getCenter() {
      if (this.customers.length) {
        if (parseFloat(this.customers[0].latitude) == null) {
          return this.latlng;
        } else {
          const latlng = {
            lat: parseFloat(this.customers[0].latitude),
            lng: parseFloat(this.customers[0].longitude),
          };
          return latlng;
        }
      }
      return this.latlng;
    },
    getMarkers(m) {
      if (m.latitude !== null) {
        return {
          lat: parseFloat(m.latitude),
          lng: parseFloat(m.longitude),
        };
      }
    },
    toggleInfo(m, key) {
      this.infoPosition = this.getMarkers(m);
      this.infoContent = `${m.priority}  ${m.name} (${m.phone})`;
      if (this.infoCurrentKey == key) {
        this.infoOpened = !this.infoOpened;
      } else {
        this.infoOpened = true;
        this.infoCurrentKey = key;
      }
    },

    getPlanDetail() {
      this.TableLoading = true;
      this.$admin.get('route-plan/' + this.$route.params.id, {
        params: {
          page: this.pagination.current_page,
          per_page: this.per_page,
        }
      }).then(res => {
        this.customers = res.data.customer.data;
        this.pagination = res.data.customer;
        this.plan = res.data.plan;
        this.TableLoading = false;
      }).catch(() => {
        this.TableLoading = false;
        this.$store.commit("Toast_State", this.toast_error);
      });
    },
    getDistrict() {
      this.TableLoading = true;
      this.$axios.get('list-districts').then(res => {
        setTimeout(() => {
          this.districts = res.data.listDistricts;
          this.TableLoading = false;
        }, 100);
      }).catch(() => {
        this.TableLoading = false;
        this.$store.commit("Toast_State", this.toast_error);
      });
    },

    afterAdd(evt) {
      const oldIndex = evt.moved.oldIndex;
      const newIndex = evt.moved.newIndex;
      let tmpOrder = this.customers[oldIndex];
      this.customers.splice(oldIndex, 1);
      this.customers.splice(newIndex, 0, tmpOrder);
    },

    orderNewCustomer(evt) {
      const oldIndex = evt.moved.oldIndex;
      const newIndex = evt.moved.newIndex;
      let tmpOrder = this.listNewCustomers[oldIndex];
      this.listNewCustomers.splice(oldIndex, 1);
      this.listNewCustomers.splice(newIndex, 0, tmpOrder);
    },
    deleteNewCustomer(index) {
      this.loading = true;
      this.listNewCustomers.splice(index, 1);
      this.$store.commit("Toast_State", {
        value: true,
        color: "success",
        msg: "ລຶບຂໍ້ມູນສຳເລັດແລ້ວ",
      });
      this.loading = false;
    },

    deleteItem(id) {
      this.routePlanDetailId = id;
      this.$store.commit("modalDelete_State", true);
    },


    AddModal() {
      this.$store.commit("modalAdd_State", true);
      this.FilterVillages();
      this.getDistrict();
      this.fetchData();
    },
    CloseModalAdd() {
      this.$store.commit("modalAdd_State", false);
    },
    openModalEdit() {
      this.$store.commit("modalEdit_State", true);
    },
    CloseModalEdit() {
      this.$store.commit("modalEdit_State", false);
    },
    closeDelete() {
      this.$store.commit("modalDelete_State", false);
    },
    deleteRoutePlanDetail() {
      this.loading = true;
      this.$admin.delete('/route-plan-detail/' + this.routePlanDetailId).then(res => {
        if (res.data.success == true) {
          this.getPlanDetail();
          this.$store.commit("modalDelete_State", false);
          this.loading = false;
          this.$store.commit("Toast_State", this.toast);
        }
      }).catch(() => {
        this.$store.commit("modalDelete_State", false);
        this.loading = true;
        this.$store.commit("Toast_State", this.toast_error);
      })
    },

    EditRouteIem() {
      const oldId = this.customers.map((item) => {
            return item.customer_id;
          }
      );
      const newId = this.listNewCustomers.map((item) => {
            return item.id;
          }
      );
      const allId = oldId.concat(newId);
      const data = {
        name: this.plan.name,
        description: this.plan.description,
        embed: this.plan.embed,
        color_code: this.plan.color_code,
        customers: allId,
      }
      if (this.customers.length > 0) {
        this.loading = true;
        this.$admin
            .post(
                "route-plan/" + this.plan.id,
                data,
                {params: {"_method": "PUT"}},
                {
                  headers: {"Content-Type": "multipart/form-data"},
                }
            )
            .then((res) => {
              if (res.status == 200) {
                this.loading = false;
              }
            })
            .catch((error) => {
              this.$store.commit("Toast_State", {
                value: true,
                color: "error",
                msg: error.response.data.message,
              });
              this.loading = false;
            });
      } else {
        this.$store.commit("Toast_State", {
          value: true,
          color: "error",
          msg: "ກາລຸນາເລືອກລູກຄ້າກ່ອນ",
        });
      }

      this.$store.commit("modalEdit_State", false);
    },
    async fetchData() {
      this.TableLoading = true;
      this.$admin.get('custom-customers', {
        params: {
          page: this.pagination.current_page,
          per_page: this.per_page,
          has_plan: this.has_plan,
          district_id: this.selectedDistrict,
          village_id: this.selectedVillages,
          search: this.search,
        }
      }).then(res => {
        this.newCustomers = res.data.customers.data;
        this.pagination = res.data.customers;
        this.TableLoading = false;
      }).catch(() => {
        this.TableLoading = false;
        // this.$store.commit("Toast_State", this.toast_error);
      });
    },
    FilterVillages() {
      this.$admin
          .get("list-address")
          .then((res) => {
            this.listVillages = res.data.listVillages;
          })
          .catch(() => {
            this.TableLoading = false;
          });
    },

    AddNewCustomerToList(array, data) {
      let newId = data.id;
      let found = false;
      for (let i = 0; i < array.length; i++) {
        if (array[i].id === newId) {
          array[i] = data;
          found = true;
        }
      }
      if (found) {
        setTimeout(() => {
          this.listNewCustomers.push(data);
        },300)
      }
      this.newCustomers.splice(data.index, 1);
      this.$store.commit("Toast_State", {
        value: true,
        color: "success",
        msg: "ເພີ່ມຂໍ້ມູນສຳເລັດແລ້ວ",
      });
    },

    UpdateRoutePlan() {
      const oldId = this.customers.map((item) => {
            return item.customer_id;
          }
      );
      const newId = this.listNewCustomers.map((item) => {
            return item.id;
          }
      );
      const allId = oldId.concat(newId);
      const data = {
        name: this.plan.name,
        description: this.plan.description,
        embed: this.plan.embed,
        color_code: this.plan.color_code,
        image: this.image,
        customers: allId,
      }
      this.loading = true;
      this.$admin
          .post(
              "route-plan/" + this.plan.id,
              data,
              {params: {"_method": "PUT"}},
              {
                headers: {"Content-Type": "multipart/form-data"},
              }
          )
          .then((res) => {
            if (res.status == 200) {
              this.loading = false;
              this.$router.push({name: 'Plan'})
            }
          })
          .catch((error) => {
            this.$store.commit("Toast_State", {
              value: true,
              color: "error",
              msg: error.response.data.message,
            });
            this.loading = false;
          });
    },

    changeStatus(user) {
      let status = null;
      this.$store.commit("progressLoading_State", true);
      if (user.status === 'active') {
        status = false;
      } else if (user.status === 'inactive') {
        status = true;
      }
      this.$admin
          .post(
              "plan-calendar/switch/" + user.id,
              {
                "status": status,
              },
          )
          .then((res) => {
            if (res.status == 200) {
              this.loading = false;
              this.$store.commit("progressLoading_State", false);
              this.getPlanDetail();
            }
          })
          .catch((error) => {
            this.$store.commit("progressLoading_State", false);
            this.$store.commit("Toast_State", {
              value: true,
              color: "error",
              msg: error.response.data.message,
            });
            this.loading = false;
          });
    },
    setStatus(value) {
      if (value == 'active') {
        return 'success';
      } else {
        return 'error';
      }
    },
    Search() {
      GetOldValueOnInput(this);
    },
  },
  watch: {
    selectedDistrict: function () {
      this.pagination.current_page = '';
      this.fetchData();
    },
    selectedVillages: function () {
      this.pagination.current_page = '';
      this.fetchData();
    },
    has_plan: function (value) {
      this.pagination.current_page = '';
      if (value == true) {
        this.has_plan = 1;
      } else {
        this.has_plan = 0;
      }
      this.fetchData();
    },
    "search": function () {
      this.pagination.current_page = '';
      setTimeout(() => {
        this.fetchData();
      }, 1000)
    }

  },
  created() {
    this.getPlanDetail();
  }
};
</script>

<style>
</style>